<template>
  <div class="web__page">
    <h1 class="v-card__title">Web page</h1>

    <div class="stories">
      <div class="stories__uploader">
        <UploadStory />
      </div>

      <div class="stories__list" v-if="getAllStories.length > 0">
        <Story v-for="s in getAllStories" :key="s.id" :story="s" />
      </div>
      <p v-else>Create a new story</p>
    </div>

    <div class="products">
      <div class="products__create">
        <button class="create__product" @click="$router.push({ name: 'product-create' })">Crear producto</button>
        <button class="create__brand" v-b-modal.create-1>Anadir marca</button>
        <button class="see__list" v-b-modal.list-1>Listado de marcas</button>
      </div>

      <div class="products__list" v-if="getAllProducts.length > 0">
        <Product v-for="p in getAllProducts" :key="p.id" :product="p"/>
      </div>
    </div>

    <b-modal id="list-1" title="Marcas registradas" ok-only scrollable>
      <p class="my-4" v-for="brand in getAllBrands" :key="brand.id">
      {{brand.name}}
      <b-icon 
        @click="deleteBrand(brand.id)"
        icon="x" 
        :style="{ 'color': 'red', 'cursor': 'pointer'}"></b-icon>
      </p>
    </b-modal>

    <b-modal id="create-1" title="Añadir marca" centered hide-footer>
      <div class="create__input">
        <input 
          v-model="nbrand"
          :style="{ 'width': '100%', 'height': '40px', 'padding': '5px'}" 
          type="text" 
          placeholder="Nombre de la marca" class="add__brand">
      </div>
      <b-button class="mt-3" block @click="newBrand()">Anadir marca</b-button>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "stories",
  components: {
    UploadStory: () => import("./components/stories/uploadStory.vue"),
    Story: () => import("./components/stories/story.vue"),
    Product: () => import('./components/product/product.vue')
  },

  data() {
    return {
      stories: [],
      nbrand: ''
    };
  },

  methods: {

    async newBrand() {
      try {
        await this.createBrand(this.nbrand)
        this.$bvModal.hide('create-1')
        this.$toast.success('Marca añadida correctamente ')
        this.nbrand = ''
      } catch (error) {
        this.$toast.error('No se añadir la marca')
      }
    },
    
    ...mapActions('webStore', ['createBrand', 'deleteBrand'])
  },

  computed: {
    ...mapGetters("webStore", ["getAllStories","getAllBrands", "getAllProducts"]),
  },
};
</script>